[class*=illustration-] {
	position: relative;

	&::after {
		pointer-events: none;
	}
}

[class*=illustration-element-]::after {
	z-index: -1;
}

[class*=illustration-section-]::after {
	z-index: -2;
}

// Hero illustration
.illustration-section-01::after {
	@include illustration('illustration-section-01.svg', 100%, 124px, top);

	@include media( '<=medium' ) {
		left: calc(50% + 60px);
		background-position: right top;
	}
}

// Hero illustration 02
.illustration-section-02::after {
	@include illustration('illustration-section-02.svg', 100%, 489px, top);

	@include media( '<=medium' ) {
		@include illustration('illustration-section-01.svg', 100%, 124px, top);
		left: calc(50% + 60px);
		background-position: right top;		
	}
}

// Testimonial illustration
.illustration-section-03::after {
	@include illustration('illustration-section-03.svg', 200%, 598px, null, null, 27px);
}

// Footer illustration
.illustration-section-04::after {
	@include illustration('illustration-section-04.svg', 100%, 100px, bottom);
}

// Behind hero figure
.illustration-element-01::after {
	@include illustration('illustration-element-01-mobile.svg', 200%, 200%);

	@include media( '>medium' ) {
		@include illustration('illustration-element-01.svg', 300%, 300%);
	}	
}

// Behind hero figure 02
.illustration-element-02::after {
	@include illustration('illustration-element-02.svg', 200%, 200%);
}

// Behind features split image
.illustration-element-03::after {
	@include illustration('illustration-element-03.svg', 200%, 200%);
}

.illustration-element-04::after {
	@include illustration('illustration-element-04.svg', 200%, 200%);
}

.illustration-element-05::after {
	@include illustration('illustration-element-05.svg', 200%, 200%);
}

// Behind pricing tab
.illustration-element-06::after {
	@include illustration('illustration-element-06.svg', 200%, 100%);
}

.illustration-element-07::after {
	@include illustration('illustration-element-07.svg', 200%, 200%);
}

// Behind team
.illustration-element-08::after {
	@include illustration('illustration-element-08.svg', 200%, 200%);
}

.illustration-element-09::after {
	@include illustration('illustration-element-09.svg', 200%, 200%);
}

.illustration-element-10::after {
	@include illustration('illustration-element-10.svg', 200%, 200%);
}